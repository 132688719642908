import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { FooterAlternate1 } from "../../components/footer"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="SalesTribe Meet-Up (Melbourne)" />

    <nav className="share-bar">
      <ul>
        <li>
          <a
            href="https://community.salestribe.com/"
            className="btn btn-default"
          >
            FREE
          </a>
        </li>
      </ul>
    </nav>
    <article className="article">
      <header className="header header--article header--event article__header">
        <div className="header__image">
          <div
            className="header__bgimage"
            style={{
              backgroundImage:
                "url(/media/images/Event-image1_pky9suY.2e16d0ba.fill-1162x655.jpg)",
            }}
          ></div>
        </div>
        <div className="header__content">
          <div className="container">
            <div className="row">
              <div className="header__inner col-md-7 col-lg-offset-1 col-lg-6">
                <Link to="/events/" className="header__back back-link">
                  <span className="sr-only">Back to all events</span>
                  <i className="icon"></i>
                </Link>
                <div className="header__title">
                  <h3>Events</h3>
                  <h1>SalesTribe Meet-Up (Melbourne)</h1>
                </div>
                {/* <h3 className="header__caption"></h3> */}
                <div className="header__date datebox">
                  <time title="20" datetime="2021-04-20">
                    20
                  </time>
                  MAY
                </div>
                <a
                  href="https://www.eventbrite.com.au/e/salestribe-meet-up-melbourne-tickets-152458488443"
                  className="btn btn-default"
                >
                  FREE
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container" id="article-content">
        <div className="row">
          <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7 large">
            <p>
              <p>
                <b>
                  To be successful in sales in 2021, you need to understand how
                  to engage an educated buyer who no longer wants to meet you in
                  person. About this event
                </b>
              </p>

              <p>
                <b>About this event</b>
              </p>

              <p>We're BACK!!!!</p>

              <p>
                Very excited to announce the resumption of our wildly popular
                Meet-Up's....that's right, actual in person Meet-Ups with Beers,
                Wine and nibbles...oh, and with other real humans!
              </p>

              <p>
                This Meet-Up event is for sales and business professionals who
                want to learn how to THRIVE in the post pandemic era - in the
                global, digital, connected economies.
              </p>

              <p>
                Come and hear what sales best practices (post-COVID) now looks
                like.
              </p>

              <ul>
                <li>
                  Leverage the NETWORK – being part of an open network or
                  community is now critical to your sales career.
                </li>
                <li>
                  Enhancing your CAREER – leveraging the right tools and
                  platforms to enhance your sales career.
                </li>
                <li>
                  LEVEL UP – ensure that you stand out from the crowd.
                  Understand how to be successful in a digital world.{" "}
                </li>
                 
              </ul>

              <p>
                This event is FREE - drinks and nibbles provided - come and be
                part of the TRIBE!
              </p>

                <img style={{ maxHeight: 300, marginBottom: 20 }} src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F133465189%2F201912215585%2F1%2Foriginal.20210427-031515?h=2000&w=720&auto=format%2Ccompress&q=75&sharp=10&s=96ed862dbfc06c5a4c073c3b26afe2c7" />

              <iframe
                title="youtube-iframe"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/sQ7eaVu4qLs"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </p>
          </div>
          <aside className="article__sidebar col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
            <div className="article__sidebar-wrap" id="article-sidebar">
              <ul className="list-group">
                <li className="list-group-item">
                  <span className="icon icon-calendar"></span>
                  <p className="list-group-item-heading">Thu, May 20, 2021</p>
                </li>
                <li className="list-group-item">
                  <span className="icon icon-clock-o"></span>
                  <p className="list-group-item-heading">6:00pm – 9:00pm</p>
                </li>
              </ul>
              <div className="article__sidebar-btns">
                <a
                  href="https://www.eventbrite.com.au/e/salestribe-meet-up-melbourne-tickets-152458488443"
                  className="btn btn-default"
                >
                  FREE
                </a>
              </div>

              <nav className="social-nav">
                <h4>Share</h4>
                <ul>
                  <li className="linkedin">
                    <a
                      href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/events/salestribe-meet-london/&amp;title=SalesTribe%20Meet%20Up%20%28London%29&amp;summary=SalesTribe%20Meet%20Up%20%28London%29&amp;source=https%3A//salestribe.com/events/salestribe-meet-london/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="sr-only">LinkedIn</span>
                      <span className="icon icon-linkedin"></span>
                    </a>
                  </li>
                  <li className="twitter">
                    <a
                      href="https://twitter.com/intent/tweet/?text=SalesTribe%20Meet%20Up%20%28London%29&amp;url=https%3A//salestribe.com/events/salestribe-meet-london/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="sr-only">Twitter</span>
                      <span className="icon icon-twitter"></span>
                    </a>
                  </li>
                  <li className="googleplus">
                    <a
                      href="https://plus.google.com/share?url=https%3A//salestribe.com/events/salestribe-meet-london/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="sr-only">Google+</span>
                      <span className="icon icon-google-plus"></span>
                    </a>
                  </li>
                  <li className="facebook">
                    <a
                      href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/events/salestribe-meet-london/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="sr-only">Facebook</span>
                      <span className="icon icon-facebook-f"></span>
                    </a>
                  </li>
                  <li className="email">
                    <a
                      href="mailto:?subject=SalesTribe%20Meet%20Up%20%28London%29&amp;body=https%3A//salestribe.com/events/salestribe-meet-london/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="sr-only">Email</span>
                      <span className="icon icon-envelope"></span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </aside>
        </div>
      </div>
    </article>

    <div className="block block--base cards">
      <div className="container">
        <div className="block__header row">
          <div className="col-sm-12 col-md-offset-1 col-md-10">
            <h2>Speakers</h2>
          </div>
        </div>
        <div className="cards__items row" data-mobile-slick>
          <div className="cards__item col-sm-6 col-md-3">
            <div className="card card--thumb">
              <div className="bgradtop50">
                <img
                  alt="GH2019"
                  className="card__image"
                  height="261"
                  src="/media/images/Graham_1539.2e16d0ba.fill-261x261.jpg"
                  width="261"
                />
              </div>
              <div className="card__inner">
                <p className="card__title">
                  <strong>Graham Hawkins</strong>
                </p>
                <p className="card__meta">CEO &amp; Founder - SalesTribe</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <div className="block block--base sponsors"> */}
    {/*   <div className="container"> */}
    {/*     <div className="block__header row"> */}
    {/*       <div className="col-sm-12 col-md-offset-1 col-md-10"> */}
    {/*         <h3> */}
    {/*           <strong>Proudly sponsored by</strong> */}
    {/*         </h3> */}
    {/*       </div> */}
    {/*     </div> */}
    {/*     <div className="sponsors__wrap row"> */}
    {/*       <div className="sponsors__item col-sm-6 col-md-3"> */}
    {/*         <img */}
    {/*           alt="Seismic" */}
    {/*           height="481" */}
    {/*           src="/media/images/testimonial-logos/seismic.jpg" */}
    {/*           width="2039" */}
    {/*         /> */}
    {/*       </div> */}
    {/*     </div> */}
    {/*   </div> */}
    {/* </div> */}

    <FooterAlternate1 />
  </Layout>
)

export default IndexPage
